import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { AuthGuard, redirectUnauthorizedTo } from '@angular/fire/auth-guard';

const routes: Routes = [
  {
    path: "",
    loadChildren: () => import("./portfolio/portfolio.module").then((m) => m.PortfolioModule),
  },
  // {
  //   path: "admin",
  //   loadChildren: () => import("./admin/admin.module").then((m) => m.AdminModule),
  //   canActivate: [AuthGuard],
  //   data: {
  //     authGuardPipe: () => redirectUnauthorizedTo(['/admin/login'])
  //   }
  // },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
