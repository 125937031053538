import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { AngularSvgIconModule } from "angular-svg-icon";
import { HttpClientModule } from "@angular/common/http";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { StorageUrlPipe } from './pipes/storage-url.pipe';

@NgModule({
  declarations: [
    StorageUrlPipe
  ],
  imports: [CommonModule, AngularSvgIconModule, HttpClientModule, FormsModule, ReactiveFormsModule],
  exports: [CommonModule, AngularSvgIconModule, HttpClientModule, FormsModule, ReactiveFormsModule, StorageUrlPipe],
})
export class SharedModule {}
